/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <div>
            <span>
            <a href="https://s3-us-west-2.amazonaws.com/legalexpressonline.com/Aviso+de+Privacidad+LEGEX.docx">Aviso de Privacidad&nbsp;&nbsp;</a>
            </span>
            <span>
            <a href="https://s3-us-west-2.amazonaws.com/legalexpressonline.com/T%C3%A9rminos+y+Condiciones+de+Uso.docx">Términos y Condiciones de Uso</a>
            </span>
          </div>
          <div className="copyright" id="copyright">
            Legex © {new Date().getFullYear()}
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
