import React from "react";

// reactstrap components
import {
  Button,
  Badge,
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  UncontrolledTooltip 
} from "reactstrap";
import { useHistory, Link } from "react-router-dom";

//import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import ContactUs from "views/ContactUs";
const diligencias_sonora = require('assets/pages/diligencias-sonora.json');
const servicios_remotos = require('assets/pages/servicios-remotos.json');
const {jumbotron, quienes_somos_txt, mensajeria_juridica_pasos, servicios_remotos_txt} = require('assets/pages/texto-secciones.json');

const diligencias_html = diligencias_sonora.map(({id, titulo, texto, precio_falso, precio, entrega, tooltip}) =>
<Col md={4} key={id}>
  <a href="#contact-us" style={{color:"#111"}}>
  <Card>
    <CardBody className="text-right">
      <CardTitle className="text-center" tag="h3">{titulo}</CardTitle>
      <CardSubtitle className="text-left" style={{padding:"1em"}} id={`${id}_body`}>
        {entrega}<br/><br/>{texto}
      </CardSubtitle>                   
      {tooltip &&<UncontrolledTooltip placement="top" target={`${id}_body`} delay={0}>
        {tooltip}
      </UncontrolledTooltip>}            
      {precio_falso &&<Badge color="default">
        <strike>{precio_falso}</strike>    
      </Badge>}
      <Badge color="success" id={`${id}_badge`}>
        {precio}
      </Badge>
      {tooltip &&
      <UncontrolledTooltip placement="bottom"  target={`${id}_badge`} delay={0}>
        {tooltip}
      </UncontrolledTooltip>}
    </CardBody>
  </Card>
  </a>
</Col>
);

const servicios_remotos_html = servicios_remotos.map(({id, titulo, texto, precio, entrega, seguimiento, notas}) =>
  <Col md={4} key={id}>
  <a href="#contact-us" style={{color:"#111"}}>
  <Card>
    <CardBody className="text-right">
      <CardTitle className="text-center" tag="h3">{titulo}</CardTitle>
      <CardSubtitle className="text-left" style={{padding:"1em"}} id={`${id}_body`}>
        {entrega}<br/><br/>{texto}<br/><br/><em>{seguimiento}</em><br/>{notas}<br/>
      </CardSubtitle>                   
      <Badge color="success">
        {precio}
      </Badge>
    </CardBody>
  </Card>
  </a>
  </Col>
);
function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="description">
                  {jumbotron}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">¿Quiénes somos?</h2>
                <h5 className="description">
                  <p>
                  {quienes_somos_txt}
                  </p>
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <h2 className="ml-auto mr-auto text-center">
              ¿Cómo funciona el servicio de mensajería jurídica?
              </h2>
              <Row>
                <Col md={6}>
                  <h5>
                  <ol>{mensajeria_juridica_pasos.map((elem) => <li>{elem}</li>)}</ol>
                  </h5>
                </Col>
                <Col md={6}>
                  <img
                    alt="..."
                    className="img-centered img-fluid img-raised"
                    src={require("assets/img/catalog.png")}
                  ></img>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
          <Container>
            <div id={'diligencias'}>
            <div className="section-story-overview">
            <h1 className="ml-auto mr-auto text-center">TRAMITES O DILIGENCIAS</h1>
            <Row className="center-block text-center">
              <Col md={10} className="text-center center-block mx-auto">
              <div
                className="image-container center-block img-responsive"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/campa.jpg") + ")"
                }}
              >
              </div>
              </Col>
            </Row>
            <Row style={{marginTop:"2em"}}>
              {diligencias_html}
            </Row>
            </div>
            </div>
              <hr style={{color: "#000"}}/>
          </Container>
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" id="divorcio_voluntario">SERVICIOS REMOTOS</h2>
              </Col>
            </Row>
              <Row><h5>{servicios_remotos_txt}</h5></Row>
            <Row style={{marginTop:"2em"}}>
              {servicios_remotos_html}
            </Row>

            <div className="separator separator-primary"></div>
            </Container>
            </div>
        <ContactUs/>
        <DefaultFooter />
      </div >
    </>
  );
}

export default LandingPage;
